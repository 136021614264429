body {
  margin: 0;
  padding: 0;
  /* font-family: Rethink Sans; */
  
  font-family: Inter;  
}

* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}

a {
  text-decoration: none;
  display: block;
}

ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
}

.white_A700_01_white_A700_7f_border {
  border-image: linear-gradient(141deg, #ffffff, #ffffff7f) 1;
}

.social_data_cards_SOOMUCH {
  width: 40%;
  transition: width 1s;
}

.text_show_none_SOOMUCH {
  display: none;
}

/* Media query for mobile devices */
@media (max-width: 1050px) {
  .text_show_none_SOOMUCH {
    display: block;
  }

  .social_data_cards_SOOMUCH {
    width: 100%;
    margin: 10px 0px 10px 0px;
  }
}

.social_data_cards_SOOMUCH:hover {
  width: 100%;
}

.social_data_cards_SOOMUCH:hover .text_show_none_SOOMUCH {
  display: block;
}


/* success data cards */

.social_success_data_cards_SOOMUCH {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #552aa1;
  overflow: hidden;
}

.social_success_data_cards_Inner_SOOMUCH {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  gap: 20px;
  text-align: center;
  padding: 0px 40px;
}

.social_success_text_show_none_SOOMUCH {
  /* display: none; */
  visibility: hidden;
  position: absolute;
  top: -500px;
  opacity: 0;
  transform: translateY(40px);
  transition: 0.5s;
}

.social_success_img_show_none_SOOMUCH {
  /* display: none; */
  height: 0px;
  width: 0px;
  position: absolute;
  border-radius: 50%;
  top: -50px;
  right: -40px;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.social_success_data_cards_SOOMUCH:hover .social_success_img_show_none_SOOMUCH {
  /* display: block; */
  /* transition: 2s; */
  height: 180px;
  width: 200px;
  transform: scale(1);
}

.social_success_text_title_show_none_SOOMUCH {
  display: block;
}

.social_success_data_cards_SOOMUCH:hover .social_success_text_show_none_SOOMUCH {
  /* display: block; */
  visibility: visible;
  position: absolute;
  top: 40%;
  transform: translateY(0px);
  opacity: 1;
}


.social_success_data_cards_SOOMUCH:hover .social_success_text_title_show_none_SOOMUCH {
  display: none;
}

.social_success_data_cards_SOOMUCH:hover .social_success_data_cards_Inner_SOOMUCH {
  align-items: start;
  flex-direction: column;
  text-align: left;
  padding: 0px 10px;
  border: 0px;
}

/* sliding cards */

ul.cards_SLIDE_SOOMUCH {
  list-style-type: none;
  position: relative;
  padding: 20px;
  height: 300px;
  /* border: 1px solid; */
  cursor: pointer;
}

ul.cards_SLIDE_SOOMUCH li.card_SLIDE_SOOMUCH {
  margin: 0px 20px;
  overflow: hidden;
  height: 300px;
  width: 250px;
  border-radius: 15px;
  border: 2px solid white;
  position: absolute;
  left: 0px;
  box-shadow: 1px 2px 2px 0 #aaa;
  transition: all 0.4s cubic-bezier(0.63, 0.15, 0.03, 1.12);

  background: #8450E7;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 1.25rem;
  justify-content: space-between;
  /* align-items: space-between; */
}

ul.cards_SLIDE_SOOMUCH li.card_SLIDE_SOOMUCH img {
  max-width: 100%;
  height: auto;
}

ul.cards_SLIDE_SOOMUCH li.card_SLIDE_SOOMUCH div.content_SLIDE_SOOMUCH {
  padding: 5px 10px;
}

ul.cards_SLIDE_SOOMUCH li.card_SLIDE_SOOMUCH.card-1_SLIDE_SOOMUCH {
  z-index: 10;
  transform: rotateZ(-2deg);
}

ul.cards_SLIDE_SOOMUCH li.card_SLIDE_SOOMUCH.card-2_SLIDE_SOOMUCH {
  z-index: 9;
  transform: rotateZ(-7deg);
  transition-delay: 0.05s;
}

ul.cards_SLIDE_SOOMUCH li.card_SLIDE_SOOMUCH.card-3_SLIDE_SOOMUCH {
  z-index: 8;
  transform: rotateZ(5deg);
  transition-delay: 0.1s;
}

ul.cards_SLIDE_SOOMUCH li.card_SLIDE_SOOMUCH.card-1_SLIDE_SOOMUCH_4 {
  z-index: 10;
  transform: rotateZ(-2deg);
}

ul.cards_SLIDE_SOOMUCH li.card_SLIDE_SOOMUCH.card-2_SLIDE_SOOMUCH_4 {
  z-index: 9;
  transform: rotateZ(-7deg);
  transition-delay: 0.05s;
}

ul.cards_SLIDE_SOOMUCH li.card_SLIDE_SOOMUCH.card-3_SLIDE_SOOMUCH_4 {
  z-index: 8;
  transform: rotateZ(5deg);
  transition-delay: 0.1s;
}

ul.cards_SLIDE_SOOMUCH li.card_SLIDE_SOOMUCH.card-4_SLIDE_SOOMUCH_4 {
  z-index: 8;
  transform: rotateZ(10deg);
  transition-delay: 0.1s;
}

ul.cards_SLIDE_SOOMUCH.transition li.card_SLIDE_SOOMUCH {
  transform: rotateZ(0deg);
}

ul.cards_SLIDE_SOOMUCH.transition li.card_SLIDE_SOOMUCH.card-1_SLIDE_SOOMUCH {
  left: 520px;
}

ul.cards_SLIDE_SOOMUCH.transition li.card_SLIDE_SOOMUCH.card-2_SLIDE_SOOMUCH {
  left: 260px;
}

ul.cards_SLIDE_SOOMUCH.transition li.card_SLIDE_SOOMUCH.card-1_SLIDE_SOOMUCH_4 {
  left: 780px;
}

ul.cards_SLIDE_SOOMUCH.transition li.card_SLIDE_SOOMUCH.card-2_SLIDE_SOOMUCH_4 {
  left: 520px;
}

ul.cards_SLIDE_SOOMUCH.transition li.card_SLIDE_SOOMUCH.card-3_SLIDE_SOOMUCH_4 {
  left: 260px;
}

/* stepper start */

.stepperWrapperOB {
  font-family: Arial;
  display: flex;
  justify-content: space-between;
}

.stepperItemOB {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepperItemOB::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #e7e6e6;
  width: 100%;
  top: 15px;
  left: -50%;
  z-index: 2;
}

.stepperItemOB::after {
  position: absolute;
  content: "";
  border-bottom: 3px solid #e7e6e6;
  width: 100%;
  top: 15px;
  left: 50%;
  z-index: 2;
}

.stepperItemOB .stepCounterOB {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #e7e6e6;
  margin-bottom: 2px;
}

.stepperItemOB.activeOB {
  font-weight: bold;
  color: rgb(47, 15, 230);
}

.stepperItemOB.completedOB .stepCounterOB {
  background: linear-gradient(120deg, #C7366B 10%, #9F33AC 50%, #8532D7 90%);
}

.stepperItemOB.completedOB::after {
  position: absolute;
  content: "";
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(120deg, #C7366B 10%, #9F33AC 50%, #8532D7 90%);
  border-image-slice: 1;
  width: 100%;
  top: 15px;
  left: 50%;
  z-index: 3;
}

.stepperItemOB:first-child::before {
  content: none;
}

.stepperItemOB:last-child::after {
  content: none;
}

/* stepper end */

/* gradient text */

.gradientTextOB {
  background: linear-gradient(120deg, #C7366B 10%, #9F33AC 50%, #8532D7 90%); /* Gradient colors */
  -webkit-background-clip: text; /* Clip the background to the text */
  color: transparent; /* Make the text color transparent */
}

/* gradient background */

.gradientBGOB {
  background: linear-gradient(120deg, #C7366B 10%, #9F33AC 50%, #8532D7 90%); /* Gradient colors */
}

/* scroll bar design start */

.scrollableContainerOB {  
  scrollbar-width: none;
}

/* scroll bar design end */