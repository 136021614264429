/* this below is for old landing page */

@tailwind base;
@tailwind components;
@tailwind utilities;

[type="text"],
input:where(:not([type])),
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  appearance: none;
  background-color: transparent;
  border-color: unset;
  border-width: 0;
  border-radius: unset;
  padding: unset;
  font-size: unset;
  line-height: unset;
}

/* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="search"]:focus,
[type="checkbox"]:focus,
[type="radio"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: transparent;
  border-color: transparent;
}
@layer components {
  /*-------------------------------------
  Container CSS
--------------------------------------*/
  .container-xs {
    @apply w-full mx-auto max-w-[1221px];
  }
}

/* this below is for new landing page */

/* @tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --amber_500: #ffc107;
    --amber_500_01: #fbbc05;
    --black_900: #000000;
    --black_900_19: #00000019;
    --black_900_1e: #0000001e;
    --blue_500: #2196f3;
    --blue_a200: #4285f4;
    --blue_gray_100: #d2d2d2;
    --blue_gray_100_01: #dbcece;
    --gray_100: #f4f4f4;
    --gray_100_00: #f5f5f500;
    --gray_100_01: #f5f5f5;
    --gray_200: #e8e8e8;
    --gray_300: #e0e0e0;
    --gray_300_01: #dfdfdf;
    --gray_800: #464646;
    --gray_900: #202020;
    --green_500: #4caf50;
    --green_600: #34a853;
    --green_600_01: #1fb04c;
    --indigo_800: #2f3291;
    --indigo_a700: rgba(94,77,224,1);
    --light_blue_a700: #0081fc;
    --red_500: #f44336;
    --red_500_01: #eb4335;
    --white_a700: #ffffff;
    --yellow_800: #f89522;

    --shadow-xs: 0 4px 16px 0 #0000001e;
    --shadow-sm: 0 2px 12px 0 #0000001e;

    --radius-xs: 6px;
    --radius-sm: 14px;
    --radius-md: 24px;

    --space-xs: 4px;
    --space-sm: 12px;
    --space-md: 20px;
    --space-lg: 24px;
    --space-xl: 48px;
  }
  [type="text"],
  input:where(:not([type])),
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    appearance: none;
    background-color: transparent;
    border-color: unset;
    border-width: 0;
    border-radius: unset;
    padding: unset;
    font-size: unset;
    line-height: unset;
  }

  [type="text"]:focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="search"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    --tw-ring-color: transparent;
    border-color: transparent;
  }
  [type="radio"]:focus,
  [type="checkbox"]:focus {
    --tw-ring-color: transparent;
  }
}
 */
