@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RethinkSansBold.ttf");
  font-family: "Rethink Sans";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RethinkSansExtraBold.ttf");
  font-family: "Rethink Sans";
  font-weight: 800;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsBlack.ttf");
  font-family: "Poppins";
  font-weight: 900;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RethinkSansMedium.ttf");
  font-family: "Rethink Sans";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RethinkSansSemiBold.ttf");
  font-family: "Rethink Sans";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/ReemKufiBold.ttf");
  font-family: "Reem Kufi";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsBold.ttf");
  font-family: "Poppins";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RethinkSansRegular.ttf");
  font-family: "Rethink Sans";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/LexendDecaBlack.ttf");
  font-family: "Lexend Deca";
  font-weight: 900;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/LexendSemiBold.ttf");
  font-family: "Lexend";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterBold.ttf");
  font-family: "Inter";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterSemiBold.ttf");
  font-family: "Inter";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsSemiBold.ttf");
  font-family: "Poppins";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterRegular.ttf");
  font-family: "Inter";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsRegular.ttf");
  font-family: "Poppins";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsMedium.ttf");
  font-family: "Poppins";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/ManropeMedium.ttf");
  font-family: "Manrope";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterSemiBold.ttf");
  font-family: "Inter";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/SchibstedGroteskSemiBold.ttf");
  font-family: "Schibsted Grotesk";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterRegular.ttf");
  font-family: "Inter";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterMedium.ttf");
  font-family: "Inter";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterBold.ttf");
  font-family: "Inter";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterLight.ttf");
  font-family: "Inter";
  font-weight: 300;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsSemiBold.ttf");
  font-family: "Poppins";
  font-weight: 600;
}
